<template>
  <v-container fluid>
    <h3 style="text-align:center;">السنة 2022 - الوزن الحالي: 100 كجم</h3>
    <br>
    <span>شهر يناير</span>
    <div class="cwidth">
    <div class="skills val" style="width: 30%;">95 كجم</div>
    </div>

    <span>شهر فبراير</span>

    <div class="cwidth">
    <div class="skills val" style="width: 50%; ">88 كجم</div>
    </div>

     <span>شهر مارس</span>

    <div class="cwidth">
    <div class="skills val" style="width: 70%; ">84 كجم</div>
    </div>

    <span>شهر إبريل</span>

    <div class="cwidth">
    <div class="skills val" style="width: 85%; ">80 كجم</div>
    </div>

    <span>شهر مايو</span>

    <div class="cwidth">
    <div class="skills val" style="width: 100%; ">75 كجم</div>
    </div>

  </v-container>
</template>

<script>
export default {

}
</script>

<style>

* {box-sizing: border-box}

.cwidth {
  width: 100%;
  margin: 10px 0;
  background-color: red;
}

.skills {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white !important;
}

.val {background-color: #04AA6D;color:#fff;}

</style>