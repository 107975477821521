<template>
  <div>

    <v-container fluid>
      <h2>{{ $t("CustomerDetails") }}</h2>
     
      <v-row v-if="details" class="mt-3">


        <!-- <v-col cols="12" sm="4">
          <div class="">
            <v-autocomplete
              style="width: 100%"
              class="d-block my-2"
              name="subsctiption"
              v-validate="''"
              data-vv-as="الاشتراكات"
              hide-details
              dense
              outlined
              v-model="filters.subsctiption"
              item-value="id"
              item-text="name"
              :items="details.subscriptions"
              @change="changeDetailsCustomer(2)"
              clearable
              placeholder="اختر اشتراك"
            ></v-autocomplete>
          </div>
        </v-col> -->

        <template v-if="detailsCustomer">

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>تاريخ بداية الاشتراك: <span dir="ltr">
              {{ detailsCustomer.subscribe.start_date }}
              </span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>تاريخ نهاية الاشتراك: <span dir="ltr">
                {{ detailsCustomer.subscribe.end_date }}
              </span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>البروتين: <span>
              {{ detailsCustomer.personal_desire.protein }}
              </span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الكارب: <span>
              {{ detailsCustomer.personal_desire.carbs }}
              </span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الاستثناءات: <span>
               {{ detailsCustomer.excluded_main_ingredients_and_not_main_ingredients }}
              </span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الباقة: <span>
               {{ detailsCustomer.package.name }}
              </span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>مميزات الباقة : 
              <span> {{ detailsCustomer.group_subscription }}</span>
            </p>
          </div>
        </v-col>

       
        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>حالة الاشتراك: <span>
                {{ detailsCustomer.subscribe.active }}
              </span></p>
          </div>
        </v-col>

         <v-col cols="12">
          <div class="customCard" style="height:auto;">
            <p> ملاحظات 
              <span> {{ detailsCustomer.personal_desire.notes }}</span>
            </p>
          </div>
        </v-col>


        

        <!-- <v-col cols="12">
          <div class="customCard" style="background: #2B542E; color: #fff">
            <p
              style="
                display: inline-block;
                margin: 0 15px;
                position: absolute;
                right: 30px;
              "
            >
              إنقاص وزن
            </p>
            <p style="display: inline-block; text-align: center !important">
              goal life cycle
            </p>
          </div>
        </v-col>

        <timeline /> -->
        

        </template>

      </v-row>

      <v-row>

        <template  v-if="detailsCustomer">
        
        <template v-if="detailsCustomer.subscription_suspension_resumptions.length>0">
          <v-col cols="12" sm="6">
            <div class="customCard" style="background: green; color: #fff">
              <p>قائمة عمليات الإيقاف والاستئناف خلال الاشتراك</p>
            </div>
          </v-col>

          
          <v-col v-if="!$vuetify.breakpoint.mdAndUp" cols="12" sm="6">
            <table id="stopContinueSubscribe">
              <tr>
                <th>رقم العملية</th>
                <th>تاريخ الإيقاف</th>
                <th>تاريخ الاستئناف</th>
              </tr>

              <template v-if="detailsCustomer.subscription_suspension_resumptions.length>0">
              <tr v-for="(d,index) in detailsCustomer.subscription_suspension_resumptions" :key="index">
                <td>{{d.id}}</td>
                <td>{{d.end_date}}</td>
                <td>{{d.start_date}}</td>
              </tr>
              </template>
              <template v-else>
                <tr 
                >
                <td colspan="3" style="color:red;">لا توجد بيانات</td> 
                </tr>
              </template>

            </table>
          </v-col>

        </template>

        <v-col v-if="detailsCustomer.subscription_compensations.length>0"  cols="12" sm="6">
          <div class="customCard" style="background: green; color: #fff">
            <p>قائمة التعويضات خلال الاشتراك</p>
          </div>
        </v-col>

        <v-col v-if="$vuetify.breakpoint.mdAndUp && detailsCustomer.subscription_suspension_resumptions.length>0" cols="12" sm="6">
          <table id="stopContinueSubscribe">
            <tr>
              <th>رقم العملية</th>
              <th>تاريخ الإيقاف</th>
              <th>تاريخ الاستئناف</th>
            </tr>

            <template v-if="detailsCustomer.subscription_suspension_resumptions.length>0">
            <tr v-for="(d,index) in detailsCustomer.subscription_suspension_resumptions" :key="index">
              <td>{{d.id}}</td>
              <td>{{d.end_date}}</td>
              <td>{{d.start_date}}</td>
            </tr>
            </template>
            <template v-else>
              <tr 
              >
              <td colspan="3" style="color:red;">لا توجد بيانات</td> 
              </tr>
            </template>

          </table>
        </v-col>

        <v-col v-if="detailsCustomer.subscription_compensations.length>0" cols="12" sm="6">
          <table id="stopContinueSubscribe">
            <tr>
              <th>عدد أيام التعويض</th>
              <th>تاريخ التعويض</th>
              <th>سبب التعويض</th>
            </tr>

            <template v-if="detailsCustomer.subscription_compensations.length>0">
            <tr v-for="(t,index) in detailsCustomer.subscription_compensations" :key="index">
              <td>{{t.number_of_days}}</td>
              <td>{{t.date}}</td>
              <td>{{t.reason}}</td>
            </tr>
            </template>
            <template v-else>
              <tr 
              >
              <td colspan="3" style="color:red;">لا توجد بيانات</td> 
              </tr>
            </template>
          </table>
        </v-col>

        </template>

      </v-row>
       
        <v-row v-if="detailsCustomer">
        <v-col cols="12">
          <div class="customCard" style="background: #342b54; color: #fff">
            <p>معلومات التوصيل</p>
          </div>
        </v-col>

        <!-- <v-col cols="12" sm="4">
          <div class="customCard">
            <p>المدينة: <span>{{ detailsCustomer.delivery.city }}</span></p>
          </div>
        </v-col> -->

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الحي: <span>{{ detailsCustomer.delivery.branch  }}</span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>فترة التوصيل: <span>{{ detailsCustomer.delivery.period  }}</span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الجهة / الشركة: <span>{{ detailsCustomer.delivery.company  }}</span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>طريقة التسليم: <span>{{ detailsCustomer.delivery.notes }}</span></p>
          </div>
        </v-col>

       
        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>اسم المندوب: <span>{{detailsCustomer.delivery.driver_name}}</span></p>
          </div>
        </v-col>

         <v-col cols="12" sm="4">
          <div class="customCard">
            <p>رقم الجوال: <span>{{detailsCustomer.delivery.driver_mobile}}</span></p>
          </div>
        </v-col>

          <!-- <v-col cols="12" sm="4">
          <div class="customCard">
            <p>مجموعات التغليف: <span>{{detailsCustomer.packaging_group_city_period}}</span></p>
          </div>
        </v-col> -->

      

        </v-row>

        <v-row v-if="detailsCustomer">
        <v-col cols="12">
          <div class="customCard" style="background: #342b54; color: #fff">
            <p>معلومات الرصيد</p>
          </div>
        </v-col>
        <template>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الرصيد الإجمالي (شامل التعويض): <span>{{detailsCustomer.subscribe.num_of_days}}</span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الرصيد المتبقي: <span>{{ detailsCustomer.subscribe.remind_days }}</span></p>
          </div>
        </v-col>

        </template>

        </v-row>

    
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import timeline from "./timeline.vue";
export default {
  name: "subscribeDetails",
  components: {
    timeline,
  },
  data() {
    return {
      showCatInfo: false,
      detailsCustomer: null,
      raseedCustomer: null,
      protinInfo: [],
      carbInfo: [],
      groupMenuCookingDailiesInfo: [],
      compliantsInfo: [],
      showCompliantsInfo: false,
      id: this.$route.params.id || null,
      details: null,
      filters: {
        subsctiption: null,
        subsctiption2: null,
        from_date: null,
        to_date: null,
        from_date_two: null,
        to_date_two: null,
      },
      subscriptionData: [
        {
          id: 1,
          name: "اشتراك 1",
        },
        {
          id: 2,
          name: "اشتراك 2",
        },
        {
          id: 3,
          name: "اشتراك 3",
        },
      ],
      compliantsInfoDetails: {
        count:0,
        count1:0,
        count2:0,
        count3:0,
      },
    };
  },
  methods: {
    changeItmesData() {

      this.showCatInfo= false;
       this.protinInfo= [];
       this.carbInfo= [];
       this.groupMenuCookingDailiesInfo= [];
       let sendData = {
        date: moment(this.filters.from_date).format('yyyy-MM-DD'),
        id: this.$route.params.id
       }

      if(this.filters.from_date) {
        this.$store.dispatch(`customers/fetchCookingDailyWithDetails`, sendData).then(res => {
          this.showCatInfo= true;
            if(res.data && res.data.proteinCookingDailies) {
              this.protinInfo = res.data.proteinCookingDailies;
            }
             if(res.data && res.data.carbCookingDailies) {
              this.carbInfo = res.data.carbCookingDailies;
            }
             if(res.data && res.data.groupMenuCookingDailies) {
              this.groupMenuCookingDailiesInfo = res.data.groupMenuCookingDailies;
            }
       })
      }

      
    },
    changeCompliantsData(fdate,tdate) {
      //  this.compliantsInfo= [];
      //   this.compliantsInfoDetails = {
      //     count: 0,
      //     count1: 0,
      //     count2: 0,
      //     count3: 0,
      //   }
      // this.showCompliantsInfo= true;
      // if (fdate != null && moment(fdate).format('DD-MM-yyyy') == '10-03-2023') {
      //   this.compliantsInfoDetails = {
      //     count: 1,
      //     count1: 0,
      //     count2: 1,
      //     count3: 0,
      //   }
      //   this.compliantsInfo= [
      //     {
      //       date: '10-03-2023',
      //       day: 'السبت',
      //       division: 'المطبخ',
      //       category: 'الأكل غير مستوي',
      //       details: 'وجبة اليوم كانت برجر وواضح أن اللحم غير مستوي',
      //     },
      //   ];
      // }
      // else if(fdate != null || tdate != null) {
      //    this.compliantsInfoDetails = {
      //     count: 3,
      //     count1: 2,
      //     count2: 0,
      //     count3: 1,
      //   }
      //   this.compliantsInfo= [
      //      {
      //       date: '10-03-2023',
      //       day: 'السبت',
      //       division: 'المطبخ',
      //       category: 'الأكل غير مستوي',
      //       details: 'وجبة اليوم كانت برجر وواضح أن اللحم غير مستوي',
      //     },
      //      {
      //       date: '11-03-2023',
      //       day: 'الأحد',
      //       division: 'المطبخ',
      //       category: 'نقص عدد الأصناف',
      //       details: 'لم يصلني فطور اليوم',
      //     },
      //      {
      //       date: '14-03-2023',
      //       day: 'الاثنين',
      //       division: 'المطبخ',
      //       category: 'نقص في ملح الطعام',
      //       details: 'الطعام مالح',
      //     }
      //   ];
      // }else {
      //   this.compliantsInfo= [];
      // }
    },
    changeDetailsCustomer() {
      this.detailsCustomer= null;
        this.$store.dispatch(`customer/getSubscription`, this.$route.params.id).then(res =>{
        this.detailsCustomer = res.data;
      })
    },
    changeRaseedCustomer(customer) {
      
      this.raseedCustomer= null;

      if(customer == 1) {
        this.raseedCustomer= {
          shamel: 200,
          remain: 50,
          items: [
            {
              date: '12/02/2023',
              day: 'الأحد',
              operation: '-1',
              raseed: '23',
              active_operation: false,
            },
            {
              date: '12/03/2023',
              day: 'الاثنين',
              operation: '2',
              raseed: '25',
              active_operation: true,
            },
            {
              date: '12/04/2023',
              day: 'الثلاثاء',
              operation: '-1',
              raseed: '24',
              active_operation: false,
            },
          ],
        }
      }
      else  if(customer == 2) {
        this.raseedCustomer= {
          shamel: 70,
          remain: 8,
          items: [],
        }
      }
      else if(customer == 3) {
        this.raseedCustomer= {
          shamel: 100,
          remain: 20,
          items: [
            {
              date: '07/02/2023',
              day: 'السبت',
              operation: '2',
              raseed: '23',
              active_operation: true,
            },
            {
              date: '12/04/2023',
              day: 'الأربعاء',
              operation: '2',
              raseed: '25',
              active_operation: true,
            },
          ],
        }
      }

    },
    goTo(routeName) {
      if (routeName == "all") {
        this.$router.push(
          "/admin/subscribe?type=&customer_name=&customer_mobile=&city_id=&branch_id="
        );
      } else if (routeName == "active") {
        this.$router.push(
          "/admin/subscribe?type=active&customer_name=&customer_mobile=&city_id=&branch_id="
        );
      } else if (routeName == "stop") {
        this.$router.push(
          "/admin/subscribe?type=stop&customer_name=&customer_mobile=&city_id=&branch_id="
        );
      } else if (routeName == "expired") {
        this.$router.push(
          "/admin/subscribe?type=expired&customer_name=&customer_mobile=&city_id=&branch_id="
        );
      }
    },
  },
  created() {

    if (this.$route.params.id) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$store
        .dispatch(`customers/findData`, this.$route.params.id)
        .then((res) => {
          this.details = res.data;
          this.changeDetailsCustomer();
          loading.close();
        });
    }
  },
};
</script>


<style>
.subscribe_details_table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.subscribe_details_table td,
.subscribe_details_table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.subscribe_details_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #8f9e06 !important;
  color: white;
}
.subscribe_details_table2 th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #0a9ca1 !important;
  color: white;
}

.customCard {
  width: 100%;
  height: 60px;
  background: #edfde4;
  border-radius: 4px;
  color: #222;
  font-weight: bold;
  border: 1px solid #ccc;
  text-align: center;
}
.card1 {
  height: auto;
}
.font-big-bold {
  font-weight: bold;
  font-size: 16px;
  padding: 20px 15px;
}
.customCard p {
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
}
.val {
  color: blue;
}
.emptyVal {
  color: red;
}

#stopContinueSubscribe,
#delivarData1,#delivarData2,#delivarData3,#delivarData4,#delivarData5 {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#stopContinueSubscribe td,
#delivarData1 td,#delivarData2 td,#delivarData3 td, #delivarData4 td,
#delivarData5 td,
#stopContinueSubscribe th,
#delivarData1 th, #delivarData2 th, #delivarData3 th, #delivarData4 th,
#delivarData5 th
  {
  border: 1px solid #ccc !important;
  padding: 8px;
  text-align: center;
}

#stopContinueSubscribe th,
#delivarData1 th, #delivarData2 th, #delivarData3 th, #delivarData4 th,
#delivarData5 th
{
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04aa6d;
  color: white;
}

#delivarData1 td, #delivarData2 td, #delivarData3 td, #delivarData4 td    {
  padding: 40px;
}

 #delivarData5 th {
  padding: 8px;
 }
  #delivarData5 td {
    padding: 8px;
  }
</style>